import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../../analytics/user';
import config from '../../config';
import { LoginStateSyncer } from '../../pages/CrossStorage/LoginStateSyncer';
import { store } from '../../store/configureStore';
import { setAPIServiceClientSecureToken } from '../../utils/setAPIClientToken';
import { AdminAccess } from '../Access';
import { UserAccess } from '../Access/UserAccess';
import { ConfirmCancelModalProvider } from '../ConfirmCancelModalContext';
import { ProvidersList } from '../ProvidersList';
import { UserContextProvider } from '../UserContext';

// TODO(drew): figure out a way to opt-into this more obviously across the app
// for routes that need it. This is only here because the routes all import this
// specific module. An alternative is to call this from each route file.
setAPIServiceClientSecureToken();

export function RoutedGamePackProviders(props: { children?: JSX.Element }) {
  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <LoginStateSyncer
      enabled={config.crossStorage.enabled}
      origin={config.crossStorage.origin}
    />,
    <UserAccess />,
    <AdminAccess />,
    <ConfirmCancelModalProvider />,
  ];

  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}
